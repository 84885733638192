import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

export default function Settings( { showPreferences , togglePreferences } ) {
  
  const settingsoptions = ['Keep', 'Pseudonymize', 'Anonymize'];
  const settings = ["People","Organisations","Phone Numbers","Zip Codes","Places","Monetary Values","E-Mails","Domains","IP Addresses"];

  const [values, setValues] = useState({});

  const handleChange = (subject) => (event) => {
    setValues({ ...values, [subject]: event.target.value });
  };

  const radios = settings.map((subject) => (
    
    <div className='matrix row' key={subject}>
      <div className='col col-1'>
        {subject}
      </div>
      <div className='col col-2'>
        <FormControl>
          <RadioGroup
            aria-labelledby={subject}
            name={subject}
            row={true}
            value={values[subject] || "Keep"}
            onChange={handleChange(subject)}
          >
            {settingsoptions.map(option => (
              <FormControlLabel 
                key={option} 
                value={option} 
                control={<Radio   
                sx={{
                  '&, &.Mui-checked': {
                    color: '#444A67',
                    fill: '#444A67', 
                    fontSize: '.8rem',
                    scale: '.6'
                  },
                  '&svg *, &path': {
                    fill: '#444A67',
                    color: '#444A67',
                  },                  
                  '&.Mui-checked': {
                    color: 'white',
                    fill: 'white', 
                    fontSize: '.8rem',
                    scale: '.6'
                  },
                }} />} 

              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  ));

  return (
    <div className='settings-container bg-gradient'>
      
        <div id="preferences" className="edit-preferences " style={ showPreferences ? { maxHeight:'500px'} : {maxHeight: '0'}}>
          <div className='matrix row'>
            <div className='col col-1'></div>
            <div className='col col-2 matrix-titles'>
              <div>Keep</div>
              <div>Pseudonymize</div>
              <div>Anonymize</div>
            </div>
          </div>
          {radios}
          <div className='matrix row' style={{padding: '10px 40px 0 40px'}}>
            <div className='col'>
            <FormControlLabel 
              control={   
                <Checkbox
                name='Whitelist'
                size="small"
                  sx={{
                    color: '#444',
                    '&.Mui-checked': {
                      color: '#444',
                    },
                  }}
                  />
                } 
                label={
                  <Typography sx={{ fontSize: '12px' }}>
                    Use Whitelist
                  </Typography>
                }
            />
          </div>
          <div className='col'>
            <FormControlLabel 
              control={   
                <Checkbox
                name='Blacklist'
                size="small"
                  sx={{
                    color: '#444',
                    '&.Mui-checked': {
                      color: '#444',
                    },
                  }}
                  />
                } 
                label={
                  <Typography sx={{ fontSize: '12px' }}>
                    Use Blacklist
                  </Typography>
                }
            />
          </div>
            <div className='col'>

            <FormControlLabel 
              control={   
                <Checkbox
                name='Lawyers'
                size="small"
                  sx={{
                    color: '#444',
                    '&.Mui-checked': {
                      color: '#444',
                    },
                  }}
                  />
                } 
            label="Keep lawyers, law firms and courts"
            />


          </div>
          </div>
        </div>
      
      <div className='preferences'>
        
          <div 
            id="set" 
            className="button button-outline done" 
            onClick={togglePreferences}
            style={ !showPreferences ? { width:'240px'} : {width: '140px'}}
          >
            { !showPreferences ? 'Set your Preferences' : 'Done' }
          </div>
      
      </div>

    </div>
  );



}
