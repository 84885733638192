import { useState } from 'react';
import Upload from './uploads.js';

export default function Tabs( {toggleSubmit } ) {
    
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [filename, setFilename] = useState("");
  //const [disabled, setDisabled] = useState(true);
 
  
  const handleFileUpload = (file) => {
    console.log(file);
    setFilename(file.name);
    setUrl('');
    setText('');
    checkInputs();
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setUrl('');
    setFilename('');
    checkInputs();
  };

  const handleURLChange = (event) => {
    setText('');
    setUrl(event.target.value);
    setFilename('');
    checkInputs();
  };

  
  const checkInputs = () => {
     const submitbutton = document.getElementById('submit-button');
    if ((filename !== '' || url !== '' || text !== '') && (filename !== '' || url.length > 5 || text.length > 5) ) {
      submitbutton.style.opacity = '1';
      toggleSubmit(false)
    } else {
      submitbutton.style.opacity = '.5';
      toggleSubmit(true)
    }
  };

    const tabdata = [
        {id : '1',
         tabTitle: "Upload file",
         tabContent: (
          <Upload onFileUpload={handleFileUpload} filename={filename} />
        ),
         tabClass: "tab-inner tab-upload"
        },
        {id : '2',
         tabTitle: "Input text",
         tabContent: (
                    <textarea
                    id='text'
                    name='Text' 
                    placeholder='Enter your Text here'
                    value={text}
                    onChange= {handleTextChange}
                    autocomplete="off"
                  />
                ),
         tabClass: "tab-inner tab-text"
        },
        {id : '3',
         tabTitle: "Input URL",
         tabContent: (
                <input
                name="URL"
                type="text" 
                placeholder='Enter URL here'
                value={url}
                onChange={handleURLChange}
                autocomplete="off"
              />
            ),
         tabClass: "tab-inner tab-url"
        }
      ]
  
        const [visibleTab, setVisibleTab] = useState(tabdata[0].id)
      
        const listTitles = tabdata.map((item) => 
            <li onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? "tab-button active" : "tab-button"}>{item.tabTitle}</li>
        )       
                                         
        const listContent = tabdata.map((item) => 
            <div className={item.tabClass} style={visibleTab === item.id ? {opacity:1} : { height: 0, opacity: 0}}>{item.tabContent}</div>
        )
        
        return(
            <div className="tabs">
              <ul className="tab-titles">
                {listTitles}
              </ul>
              <div className="tab-content">
                {listContent}
              </div>
            </div>
          )
      
    }