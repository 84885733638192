import logo from './logo.svg';
import bgimage from './neo-bg.png';
import shield from './SHIElD.svg';
import './App.css';
import Form from './components/form.js';


function App() {
  return (
    <>
    <div className='bg-container' >
      <img src={bgimage} className="bg-image" alt="logo"  />
    </div>
    <div className="header">
      <header className="neo-shield-header">
      <a href='/'>
        <img src={logo} className="logo" alt="logo" />
        <img src={shield} className="shield" alt="logo" />
      </a>
      </header>
    </div>
    <div id="neo-shield-form" className='neo-shield-app' >   
          <Form />
    </div>
    <div id="app-response" className='app-response'>
      <div>Whatever happens here, it's magic.</div>
    </div>
    </>
  );

  
}

export default App;
