import { useState } from 'react';
import Tabs from './tabs.js';
import Settings from './settings.js';

export default function Form() {
  
  const [showPreferences, setShowPreferences] = useState(false);
  const [neokey, setKey] = useState("");
  const [casenumber, setCase] = useState("");
  //const [disabled, setDisabled] = useState(true);

  const togglePreferences = () => {
    setShowPreferences(!showPreferences);
  }

  const toggleSubmit = (e) => {
    console.log(e)
    //setDisabled(e);
  }

  function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    // Or you can work with it as a plain object:
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);
    console.log(formData);
    const neoform = document.getElementById('neo-shield-form');
    neoform.style.opacity = '0';
    //neoform.style.display = 'none';
    const neoresponse = document.getElementById('app-response');
    neoresponse.style.display = 'flex';
    neoresponse.style.opacity = '1';
    const apiurl = window.API_URL;
    // Using Fetch API
    fetch(apiurl, {
      method: 'POST',
      body: formData,
      headers: {
        //'Content-type': 'multipart/form-data; charset=UTF-8',
      },
    })
      .then((response) => response.text())
      .then((data) => {
          console.log(data);
            neoresponse.innerHTML = data;
      })
      .catch((err) => {
          console.log(err.message);
          neoresponse.innerHTML = 'processing your request was not possible.<br> Please try again';
      });
  }

    return (
      <form className='neo-form'  onSubmit={handleSubmit} encType="multipart/form-data">
        
        <div className='neo-shield-form-wrapper' style={ showPreferences ? { marginTop:'130px'} : {} }>
          
          <div className=''>
            
            <div className={"tab-preferences " + showPreferences} style={ showPreferences ? { maxHeight:'0'} : {maxHeight: '200px'}}>
              <Tabs toggleSubmit={toggleSubmit} disabled={false} />
            </div>
            
            <Settings togglePreferences={togglePreferences} showPreferences={showPreferences} />
          
          </div>

        </div>

        <div className='row additional-fields'>
          <div className='col text-center'>
          <label>
            Case Number
            <input
              name='Case number' 
              className='input-transparent'
              label='Case number'
              value={casenumber}
              autocomplete="off"
              onChange={(e) => setCase(e.target.value)}
            />
          </label>
          </div>
          <div className='col text-center'>
          <label>
            NEO Key
            <input
              name='NEO Key' 
              className='input-transparent'
              value={neokey}
              autocomplete="off"
              onChange={(e) => setKey(e.target.value)}
            />
          </label>
          </div>
        </div>
        <div className='row submit'>
          <button id="submit-button" className='button button-primary' type="submit">Submit form</button>
        </div>
      </form>
    )
    

}